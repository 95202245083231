import store from "@/state/store";

export default [
  {
    path: "/unauthorized",
    name: "Unauthorized",
    meta: {
      title: "Non Autorisé",
      authRequired: false,
    },
    component: () => import("../views/auth/500.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },

    },
  },
  {
    path: "/transactions/:id/cancel",
    name: "cancelTransaction",
    component: () => import("../views/transactions/cancel.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },

    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      title: "Tableau de bord",
      authRequired: true,
      key: "dashboard"
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      title: "Tableau de bord",
      authRequired: true,
      key: "transaction"
    },
    children: [
      {
        path: "",
        name: "listeTransactions",
        meta: {
          title: "Transactions",
          authRequired: true,
          key: "transactions"

        },
        component: () => import("../views/transactions/index.vue"),
      },
    ]

  },
  {
    path: "/clients",
    name: "clients",
    meta: {
      title: "Clients",
      authRequired: true,
      key: "customer"
    },
    children:[
      {
        path: "",
        name: "listeClient",
        meta: {
          title: "Clients",
          authRequired: true,
          key: "customer"

        },
        component: () => import("../views/clients/index.vue"),
      },
      {
        path: "create",
        name: "createClient",
        meta: {
          title: "Clients",
          authRequired: true,
          key: "create-client"

        },
        component: () => import("../views/clients/create.vue"),
      },
      {
        path: ":id/edit",
        name: "editClient",
        meta: {
          title: "Materiel",
          authRequired: true,
          key: "edit-client"

        },
        component: () => import("../views/clients/edit.vue"),
      },
      {
        path: ":id/fiche",
        name: "ficheClient",
        meta: {
          title: "Clients",
          authRequired: true,
          key: "fiche-client"

        },
        component: () => import("../views/clients/edit.vue"),
      },
      {
        path: ":id/mesure-client",
        name: "editMesureClient",
        meta: {
          title: "Mesure",
          authRequired: true,
          key: "mesure-client"

        },
        component: () => import("../views/clients/mesures.vue"),
      },
      {
        path: ":id/commandes",
        name: "commandeClient",
        meta: {
          title: "Commandes",
          authRequired: true,
          key: "commande-client"

        },
        component: () => import("../views/clients/commandes.vue"),
      }
    ]
  },
  {
    path: "/commandes",
    name: "commandeClients",
    meta: {
      title: "Commande client",
      authRequired: true,
      key:"commande"
    },
    children:[
      {
        path: "en-attentes",
        name: "listeCommandesEnAttente",
        meta: {
          title: "Commandes",
          authRequired: true,
          key:"commande-en-attentes"

        },
        component: () => import("../views/commandes/en_attente"),

      },
      {
        path: ":id/devis",
        name: "devisCommandes",
        meta: {
          title: "Commandes",
          authRequired: true,
          key:"devis-commande"

        },
        component: () => import("../views/commandes/devis"),

      },
      {
        path: ":id/facture",
        name: "factureCommandes",
        meta: {
          title: "Commandes",
          authRequired: true,
          key:"facture-commande"

        },
        component: () => import("../views/commandes/facture"),

      },
      {
        path: ":id/paiement",
        name: "paiementCommandes",
        meta: {
          title: "Commandes",
          authRequired: true,
          key:"paiement-commande"

        },
        component: () => import("../views/commandes/paiement"),

      },


    ]
  },

  {
    path: "/settings",
    name: "companySettings",
    meta: {
      title: "Paramètres",
      authRequired: true,
    },
    children:[
      {
        path: "general",
        name: "generalSetting",
        meta: {
          title: "Paramètres généraux",
          authRequired: true,
        },
        component: () => import("../views/parametres/general.vue"),
      },
      {
        path: "type-couture",
        name: "settingTypeCouture",
        meta: {
          title: "Type de couture",
          authRequired: true,
        },
        children:[
          {
            path: "",
            name: "listTypeCouture",
            meta: {
              title: "Type de couture",
              authRequired: true,
            },
            component: () => import("../views/parametres/type_coutures/index.vue"),
          },
          {
            path: "create",
            name: "createTypeCouture",
            meta: {
              title: "Type de couture",
              authRequired: true,
            },
            component: () => import("../views/parametres/type_coutures/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editTypeCouture",
            meta: {
              title: "Type de couture",
              authRequired: true,
            },
            component: () => import("../views/parametres/type_coutures/edit.vue"),
          }
        ]
      },
      {
        path: "specialites",
        name: "settingSpecialite",
        meta: {
          title: "Specialites",
          authRequired: true,
        },
        children:[
          {
            path: "",
            name: "listeSpecialite",
            meta: {
              title: "Specialite",
              authRequired: true,
            },
            component: () => import("../views/parametres/specialites/index.vue"),
          },
          {
            path: "create",
            name: "createSpecialite",
            meta: {
              title: "Specialite",
              authRequired: true,
            },
            component: () => import("../views/parametres/specialites/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editSpecialite",
            meta: {
              title: "Specialite",
              authRequired: true,
            },
            component: () => import("../views/parametres/specialites/edit"),
          }
        ]
      },
      {
        path: "materiel",
        name: "settingMateriel",
        meta: {
          title: "Materiel",
          authRequired: true,
        },
        children:[
          {
            path: "",
            name: "listeMateriel",
            meta: {
              title: "Materiel",
              authRequired: true,
            },
            component: () => import("../views/parametres/materiel/index.vue"),
          },
          {
            path: "create",
            name: "createMateriel",
            meta: {
              title: "Materiel",
              authRequired: true,
            },
            component: () => import("../views/parametres/materiel/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editMateriel",
            meta: {
              title: "Materiel",
              authRequired: true,
            },
            component: () => import("../views/parametres/materiel/edit.vue"),
          }
        ]
      },
      {
        path: "modeles",
        name: "settingModeles",
        meta: {
          title: "Modèles",
          authRequired: true,
        },
        children:[
          {
            path: "",
            name: "listeModeles",
            meta: {
              title: "Modèles",
              authRequired: true,
            },
            component: () => import("../views/parametres/modeles/index.vue"),
          },
          {
            path: "create",
            name: "createModele",
            meta: {
              title: "Modèles",
              authRequired: true,
            },
            component: () => import("../views/parametres/modeles/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editModele",
            meta: {
              title: "Materiel",
              authRequired: true,
            },
            component: () => import("../views/parametres/modeles/edit.vue"),
          },
          {
            path: "categories",
            name: "categorieModele",
            meta: {
              title: "Modèles",
              authRequired: true,
            },
            children: [
              {
                path: "",
                name: "listeCategorieModeles",
                meta: {
                  title: "Modèles",
                  authRequired: true,
                },
                component: () => import("../views/parametres/modeles/categorie/index.vue"),
              },
              {
                path: "",
                name: "createCategorieModeles",
                meta: {
                  title: "Modèles",
                  authRequired: true,
                },
                component: () => import("../views/parametres/modeles/categorie/create.vue"),
              },
              {
                path: ":id/edit",
                name: "editCategorieModeles",
                meta: {
                  title: "Modèles",
                  authRequired: true,
                },
                component: () => import("../views/parametres/modeles/categorie/edit.vue"),
              },

            ]
          }
        ]
      },
      {
        path: "libelle-mesure",
        name: "settingLibelleMesure",
        meta: {
          title: "Libellé mesure",
          authRequired: true,
        },
        children:[
          {
            path: "",
            name: "listeLibelleMesure",
            meta: {
              title: "Libellé mesure",
              authRequired: true,
            },
            component: () => import("../views/parametres/libelle_mesure/index.vue"),
          },
          {
            path: "create",
            name: "createLibelleMesure",
            meta: {
              title: "Materiel",
              authRequired: true,
            },
            component: () => import("../views/parametres/libelle_mesure/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editLibelleMesure",
            meta: {
              title: "Materiel",
              authRequired: true,
            },
            component: () => import("../views/parametres/libelle_mesure/edit.vue"),
          }
        ]
      },
      {
        path: "utilisateurs",
        name: "settingGestionsUtilisateurs",
        meta: {
          title: "Utilisateurs",
          authRequired: true,
        },
        children:[
          {
            path: "roles",
            name: "settingRolesUtilisateurs",
            meta: {
              title: "Role utilisateurs",
              authRequired: true,
            },
            children: [
              {
                path: "",
                name: "listeRoles",
                meta: {
                  title: "Liste des roles",
                  authRequired: true,
                },
                component: () => import("../views/parametres/roles/index"),
              },
              {
                path: "create",
                name: "createRoles",
                meta: {
                  title: "Creation des roles",
                  authRequired: true,
                },
                component: () => import("../views/parametres/roles/create"),
              },
              {
                path: ":id/edit",
                name: "editRoles",
                meta: {
                  title: "Modification des roles",
                  authRequired: true,
                },
                component: () => import("../views/parametres/roles/edit"),
              },

            ]
          },

          {
            path: "",
            name: "listeUtilisateurs",
            meta: {
              title: "Liste des utilisateurs",
              authRequired: true,
            },
            component: () => import("../views/parametres/users/liste.vue"),
          },
          {
            path: "create",
            name: "createUtilisateurs",
            meta: {
              title: "Creation des utilisateurs",
              authRequired: true,
            },
            component: () => import("../views/parametres/users/create"),
          },
          {
            path: ":id/edit",
            name: "editUtilisateurs",
            meta: {
              title: "Modification des roles",
              authRequired: true,
            },
            component: () => import("../views/parametres/users/edit"),
          },

        ]
      },



    ]
  },

];