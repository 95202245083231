import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  state : {
    currentUser: JSON.parse(localStorage.getItem('currentUser')),
    daterange: localStorage.getItem('daterange')

  },
  modules,

  mutations : {

    SET_CURRENT_USER(state, newValue) {
      state.currentUser = newValue
      saveState('currentUser', newValue)
    },

  },
  getters : {
    // Whether the user is currently logged in.
    loggedIn(state) {
      return !!state.currentUser
    },

  },
  actions : {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars


    // Logs in the current user.
    logIn({ commit }, user= {}) {
      commit('SET_CURRENT_USER', user)
    },


  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}